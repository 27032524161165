import $ from 'jquery';
/* global Modernizr */
const scrollTo = ($toContainer, callback) => {
  const itemPrefix = 0;
  const offsetTop = 0;

  if ($toContainer instanceof $) {
    $('html, body').animate(
      { scrollTop: $toContainer[itemPrefix].offsetTop - offsetTop },
      'slow',
      () => {
        if (callback !== undefined) {
          callback();
        }
      },
    );
  } else {
    $('html, body').animate({ scrollTop: '0px' }, 'slow');
  }
};

const initScroll = () => {
  $(window).scroll(() => {
    const $nav = $('#topNavbar');
    const $navImage = $('#topNavbar .navbar-brand');
    const body = $('body');
    const top = 10;

    if ($(window).scrollTop() >= top) {
      $nav.addClass('navbar-white');
      $navImage.addClass('fade-in').removeClass('fade-in-up');
      body.addClass('body-scroll');
    } else {
      $nav.removeClass('navbar-white -in');
      if ($navImage.hasClass('fade-in')) {
        $navImage.addClass('fade-in-up');
      }

      $navImage.removeClass('fade-in');
      body.removeClass('body-scroll');
    }
  });
};

const toggleChat = () => {
  $('.offer-expand .btn-scroll-down').on('click', () => {
    $('.offer-wrapper').toggleClass('expanded');
  });

  $('.collapse-experience').on('show.bs.collapse', () => {
    $('.collapse-expander').addClass('expanded');
  });

  $('.collapse-offer .collapse').on('show.bs.collapse', e => {
    const parentId = e.currentTarget.id;

    $('.collapse-offer').removeClass('collapse');
    $(`[data-target="#${parentId}"]`).addClass('expanded');
  });

  $('.offer-expand button').on('click', () => {
    $('.collapse-offer .collapse').addClass('show');
  });

  $('.collapse-experience').on('hide.bs.collapse', () => {
    $('.collapse-expander').removeClass('expanded');
  });

  $('.collapse-offer .collapse').on('hide.bs.collapse', e => {
    const parentId = e.currentTarget.id;

    $('.collapse-offer').removeClass('collapse');
    $(`[data-target="#${parentId}"]`).removeClass('expanded');
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('.btn-form').on('click', () => {
    $('.offer-options').hide();
    $('.offer-expand').hide();
    $('.offer-form').show();
  });

  $('.btn-form-back').on('click', () => {
    $('.offer-options').show();
    $('.offer-expand').show();
    $('.offer-form').hide();
  });

  const ELEMENT_INDEX = 0;

  $(window).on('activate.bs.scrollspy', () => {
    const $activeSidebar = $('#sidebarNav a.active');

    if ($activeSidebar.length) {
      if ($('#sidebarNav a.active')[ELEMENT_INDEX].hasAttribute('data-dark')) {
        $('body').addClass('inverse');
      } else {
        $('body').removeClass('inverse');
      }

      $('.navbar-collapse .nav-link').removeClass('active');

      const $activeNav = $(
        `.navbar-collapse .nav-link[data-scroll="${$activeSidebar.data(
          'scroll',
        )}"]`,
      );

      $activeNav.addClass('active');
    }
  });

  $('.collapse-offer button').on('click', e => {
    const $btn = $(e.currentTarget);
    const topic =
      $btn
        .parents('.multi-collapse')
        .find('h2')
        .text() || '';

    $('#sportInput').val(topic);
  });

  document.addEventListener(
    'wpcf7mailsent',
    () => {
      $('.wpcf7-form').hide();
      $('.alert').addClass('u-hidden');
      $('.alert-success')
        .removeClass('u-hidden')
        .hide()
        .fadeIn();

      $('html,body').scrollTop(0);

      setTimeout(() => {
        $('.wrapper-multi-collapse').collapse('toggle');
        $('.wpcf7-form').show();
        $('.wpcf7-form')[0].reset();
        $('.alert').addClass('u-hidden');
      }, 3000);
    },
    false,
  );

  document.addEventListener(
    'wpcf7mailfailed',
    () => {
      $('.alert-error').removeClass('u-hidden');
    },
    false,
  );

  const $topSection = $('.c-section__top');

  function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img.height);
      img.onerror = reject;
      img.src = src;
    });
  }

  function process(path, className) {
    addImageProcess(path).then(() => {
      $topSection.addClass(className);
    });
  }

  function webpProcess(config, psBackgroundValue) {
    Modernizr.on('webp', result => {
      if (result) {
        process(config.imagesPath.webp, config.className);
      } else {
        process(config.imagesPath.jpg, config.className);
      }
    });

    localStorage.setItem('psBackground', psBackgroundValue);
  }

  function background() {
    const bg = localStorage.getItem('psBackground');
    const basePath = $('[data-base-path]').data('base-path');

    const sectionConfig = {
      first: {
        className: 'c-section-bg-1',
        imagesPath: {
          webp: `${basePath}/assets/images/webp/section-bg-1.webp`,
          jpg: `${basePath}/assets/images/section-bg-1.jpg`,
        },
      },
      second: {
        className: 'c-section-bg-2',
        imagesPath: {
          webp: `${basePath}/assets/images/webp/section-bg-2.webp`,
          jpg: `${basePath}/assets/images/section-bg-2.jpg`,
        },
      },
    };

    return bg && bg === 'true'
      ? webpProcess(sectionConfig.first, false)
      : webpProcess(sectionConfig.second, true);
  }

  background();

  $('.offer-container-wrapper').addClass('show');
};

// hashScroll: function() {
//   var a = this;
//   if (window.location.hash.length > 0) {
//       var b = window.location.hash.replace("#", "")
//         , c = $("[data-id=" + b + "]");
//       c.length > 0 && setTimeout(function() {
//           a.helpers.scrollTo(c)
//       }, 300);
//       try {
//           history.replaceState("", document.title, window.location.pathname)
//       } catch (d) {}
//   }
// },

const initAnimations = () => {
  $('.animate-drop').addClass('animate-drop-end');

  $('[data-scroll]').on('click', event => {
    const $container = $(
      `[data-id="${$(event.currentTarget).data('scroll')}"]`,
    );
    const $toggle = $('.navbar-toggler');
    const $button = $(event.currentTarget);
    const $collapse = $(event.currentTarget).parents('.navbar-collapse.show');

    function closeNav() {
      if ($collapse.length && $button.hasClass('nav-link')) {
        $toggle.trigger('click');
      }
      $button.blur();
    }

    if ($(event.currentTarget).hasClass('active')) {
      return false;
    }

    if ($container.length) {
      $('.navbar').addClass('hide-top');
      scrollTo($container, closeNav);
    }
    return false;
  });
};

export { initScroll, initAnimations, toggleChat };
